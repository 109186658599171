<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <div class="card card-custom">
          <!--begin::Create-->
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("c_management.buy_from_outpurchase") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/users-quicksalebuy"
                class="btn btn-sm btn-secondary text-primary font-weight-bold"
              >
                <i class="flaticon2-back text-primary fa-1x"></i> {{ $t("commons.back") }}
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-start">
              <div class="col-12 justify-content-start mb-5">
                <!--begin::transaction-details-->
                <div class="border-bottom border-bottom-dark mb-10 pb-5">
                  <p class="text-center font-weight-bolder text-uppercase">{{ $t("merchant.details_transaction_modaltitle") }}</p>
                  <div class="pt-5 flex-column d-flex" v-if="isLoading">
                    <div class="d-flex justify-content-between align-items-center">
                      <p><Skeleton height="38px" /></p>
                      <b><Skeleton height="38px" /></b>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <p><Skeleton height="38px" /></p>
                      <b><Skeleton height="38px" /></b>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <p><Skeleton height="38px" /></p>
                      <b><Skeleton height="38px" /></b>
                    </div>
                  </div>
                  <div class="pt-5 flex-column d-flex" v-else>
                    <div class="d-flex justify-content-between align-items-center">
                      <p>
                        {{ $t("merchant.details_bs_transaction_type") }}
                      </p>
                      <b>{{ $t(`c_management.trading_transaction_types.${transaction.type}`) }}</b>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <p>
                        {{ $t("merchant.details_bs_transaction_amount") }}
                      </p>
                      <b>{{ transaction.amount_decimal }} {{ transaction.currency_code | uppercase }}</b>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <p>
                        {{ $t("merchant.details_bs_transaction_awr") }}
                      </p>
                      <b>{{ transaction.reel_aveible_decimal }} {{ transaction.currency_code | uppercase }}</b>
                    </div>
                  </div>
                </div>
                <!--end::transaction-details-->
                <!--begin::Form Wizard-->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form class="form">
                    <!--begin::Step 1-->
                    <div
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("merchant.provider")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('merchant.provider')"
                              v-slot="{ errors, classes }"
                              v-if="!isLoading"
                            >
                              <multiselect
                                v-model="selectedProvider"
                                :options="providers"
                                :internal-search="true"
                                :allow-empty="false"
                                open-direction="bottom"
                                deselect-label=""
                                select-label=""
                                selected-label=""
                                track-by="label"
                                label="label"
                                :show-labels="false"
                                :placeholder="$t('merchant.provider')"
                                :class="classes"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  >{{ option.label }}</template
                                >
                                <template
                                  slot="option"
                                  slot-scope="{ option }"
                                  >{{ option.label }}</template
                                >
                                <template slot="noOptions">{{
                                  "List is empty"
                                }}</template>
                              </multiselect>
                              <span class="error__message">{{
                                errors[0]
                              }}</span>
                            </validation-provider>
                            <div v-else>
                              <Skeleton height="38px" />
                              <span class="error__message"></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("finance.amount")
                            }}</label>
                            <input
                              disabled
                              class="form-control"
                              :value="transaction.amount_decimal"
                              v-if="!isLoading"
                            />
                            <div v-else>
                              <Skeleton height="38px" />
                              <span class="error__message"></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("finance.fee")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('finance.fee')"
                              v-slot="{ classes, errors }"
                              v-if="!isLoading"
                            >
                              <input
                                :class="classes"
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                min="0.00"
                                autocomplete="off"
                                class="form-control"
                                v-model="fee"
                              />
                              <span class="error__message">{{
                                errors[0]
                              }}</span>
                            </validation-provider>
                            <div v-else>
                              <Skeleton height="38px" />
                              <span class="error__message"></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("commons.price")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('commons.price')"
                              v-slot="{ classes, errors }"
                              v-if="!isLoading"
                            >
                              <currency-input
                                class="form-control"
                                :placeholder="$t('commons.price')"
                                :class="classes"
                                :precision="pricePrecision"
                                v-model="price"
                              />
                              <span class="error__message">{{
                                errors[0]
                              }}</span>
                            </validation-provider>
                            <div v-else>
                              <Skeleton height="38px" />
                              <span class="error__message"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-center pt-10">
                      <form-submit-btn
                        :btnClass="'btn btn-pill btn-success w-75'"
                        :isSubmitting="isSubmitting"
                        @click="handleSubmit(_handleSubmit)"
                        :text="$t('commons.save')"
                      />
                    </div>
                    <!--end::Actions-->
                  </form>
                </ValidationObserver>
                <!--end::Form Wizard-->
              </div>
            </div>
          </div>
          <!--end::Create-->
        </div>
      </div>
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import { Skeleton } from 'vue-loading-skeleton';


// import i18n from '@/config/_i18n'

const providers = [{ value: "nexus", label: "Nexus" }];

export default {
  name: "Withdraw",
  data() {
    return {
      providers: providers,
      selectedProvider: providers[0],
      fee: null,
      price: null,
      pricePrecision: 8,
      isSubmitting: false,
    };
  },
  components: {
    Multiselect,
    Breadcrumb,
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: state => state.customerManagement.isLoading,
      currencies: state => state.customerManagement.buyFromOutPurchaseData.currencies,
      model: state => state.customerManagement.buyFromOutPurchaseData.model,
      model_id: state => state.customerManagement.buyFromOutPurchaseData.model_id,
      transaction: state => state.customerManagement.buyFromOutPurchaseData.transaction,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("c_management.title"),
        subtitle: [
          { title: this.$t("c_management.trading_transactions"), route: '/users-quicksalebuy' },
          this.$t("c_management.buy_from_outpurchase"),
        ],
      };
    },
  },
  methods: {
    async _handleSubmit() {
      const formData = {
        model: this.model,
        model_id: this.model_id,
        provider: this.selectedProvider.value,
        amount: this.transaction?.amount_decimal || '0',
        fee: this.fee,
        price: this.price,
      };
      this.$store.dispatch('customerManagement/TRADING_OUT_PURCHASE_STORE', formData);
    },
  },
  created() {
    window.scrollTo({ top: 0 })
    this.$store.dispatch('customerManagement/TRADING_OUT_PURCHASE_CREATE', this.$route.params.id)
  },
};
</script>
